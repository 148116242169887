import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				StarGaze Events
			</title>
			<meta name={"description"} content={"Creare ricordi indimenticabili"} />
			<meta property={"og:title"} content={"StarGaze Events"} />
			<meta property={"og:description"} content={"Creare ricordi indimenticabili"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header2>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-15">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				justify-content="space-between"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						md-text-align="left"
					>
						<Strong>
							La nostra missione
						</Strong>
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						A StarGaze Events, la nostra missione è trasformare ogni evento in un'esperienza indimenticabile. Con un team di professionisti appassionati e creativi, ci dedichiamo a realizzare la tua visione, curando ogni dettaglio per garantire un evento perfetto. Che si tratti di un matrimonio da favola, una festa aziendale di successo o una celebrazione intima, ci impegniamo a superare le tue aspettative con servizi personalizzati e di alta qualità.
					</Text>
					<Link
						href="/about"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="2rem 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-primary"
						hover-transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-primary"
						text-align="center"
					>
						Pronto a pianificare il tuo evento perfetto?
					</Link>
				</Box>
				<Box width="100%" display="flex" sm-flex-wrap="wrap">
					<Box
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
					>
						<Box
							overflow-x="hidden"
							overflow-y="hidden"
							position="relative"
							transform="translateY(0px)"
							hover-transform="translateY(-10px)"
							width="100%"
							height="auto"
							transition="transform 0.2s ease-in-out 0s"
							padding="0px 0px 125% 0px"
						>
							<Image
								width="100%"
								left={0}
								src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08:22:40.334Z"
								object-fit="cover"
								position="absolute"
								display="block"
								top="auto"
								right={0}
								bottom="0px"
								min-height="100%"
								srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
						display="flex"
					>
						<Box
							width="100%"
							height="auto"
							position="relative"
							padding="0px 0px 125% 0px"
							overflow-x="hidden"
							overflow-y="hidden"
							transform="translateY(0px)"
							transition="transform 0.2s ease-in-out 0s"
							hover-transform="translateY(-10px)"
						>
							<Image
								display="block"
								width="100%"
								top="auto"
								right={0}
								position="absolute"
								object-fit="cover"
								left={0}
								bottom="0px"
								min-height="100%"
								src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08:22:40.331Z"
								srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						flex-direction="column"
						sm-width="50%"
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
					>
						<Box
							overflow-x="hidden"
							overflow-y="hidden"
							position="relative"
							transition="transform 0.2s ease-in-out 0s"
							hover-transform="translateY(-10px)"
							height="auto"
							transform="translateY(0px)"
							padding="0px 0px 125% 0px"
							width="100%"
						>
							<Image
								object-fit="cover"
								width="100%"
								right={0}
								bottom="0px"
								min-height="100%"
								src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08:22:40.319Z"
								position="absolute"
								display="block"
								top="auto"
								left={0}
								srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
					>
						<Box
							position="relative"
							transition="transform 0.2s ease-in-out 0s"
							width="100%"
							height="auto"
							overflow-y="hidden"
							padding="0px 0px 125% 0px"
							overflow-x="hidden"
							transform="translateY(0px)"
							hover-transform="translateY(-10px)"
						>
							<Image
								object-fit="cover"
								position="absolute"
								width="100%"
								left={0}
								bottom="0px"
								src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"
								display="block"
								top="auto"
								right={0}
								min-height="100%"
								srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					padding="0px 0px 90% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-4%20%281%29.jpg?v=2024-06-10T08:22:40.346Z"
						object-fit="cover"
						left={0}
						right={0}
						min-height="auto"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-4%20%281%29.jpg?v=2024-06-10T08%3A22%3A40.346Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-4%20%281%29.jpg?v=2024-06-10T08%3A22%3A40.346Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-4%20%281%29.jpg?v=2024-06-10T08%3A22%3A40.346Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-4%20%281%29.jpg?v=2024-06-10T08%3A22%3A40.346Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-4%20%281%29.jpg?v=2024-06-10T08%3A22%3A40.346Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-4%20%281%29.jpg?v=2024-06-10T08%3A22%3A40.346Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-4%20%281%29.jpg?v=2024-06-10T08%3A22%3A40.346Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark" display="block">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
				<Strong>
					Il nostro approccio
				</Strong>
			</Text>
			<Box
				width="100%"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--light"
					font="--base"
					md-margin="0px 0px 20px 0px"
					opacity="1"
				>
					Il nostro approccio è basato sulla collaborazione e sulla personalizzazione. Lavoriamo a stretto contatto con te per capire i tuoi desideri e le tue esigenze, creando un piano dettagliato che riflette la tua personalità e il tuo stile. Dalla pianificazione iniziale alla realizzazione finale, ogni passo del processo è gestito con professionalità e attenzione ai dettagli, assicurando un evento senza stress e indimenticabile.
				</Text>
			</Box>
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				margin="2rem 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					<Strong>
						Esperienza e passione
					</Strong>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--light"
					font="--base"
					md-margin="0px 0px 20px 0px"
					opacity="1"
				>
					Il team di StarGaze Events è composto da esperti nel settore degli eventi con anni di esperienza e una passione infinita per la creazione di momenti magici. La nostra squadra comprende pianificatori di eventi, designer, coordinatori e specialisti del catering, tutti dedicati a fornire un servizio eccezionale. La nostra esperienza ci consente di affrontare qualsiasi sfida con creatività e competenza, garantendo il successo del tuo evento.
				</Text>
			</Box>
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				margin="2rem 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					<Strong>
						Collaborazione e creatività
					</Strong>
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--lead">
					Crediamo nel potere della collaborazione e della creatività. Ogni membro del nostro team porta una prospettiva unica e una serie di competenze che arricchiscono il processo di pianificazione. Lavoriamo insieme, condividendo idee e ispirazioni per creare eventi che sono non solo belli, ma anche significativi. La nostra creatività ci permette di offrire soluzioni innovative e personalizzate, rendendo ogni evento unico.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						FAQ
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							<Strong>
								1. Quali tipi di eventi organizzate?
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Organizziamo una vasta gamma di eventi, inclusi matrimoni, feste aziendali, compleanni, eventi di beneficenza, conferenze e molto altro. Personalizziamo ogni evento in base alle esigenze e ai desideri dei nostri clienti.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							<Strong>
								2. Offrite pacchetti di pianificazione completi o posso scegliere solo alcuni servizi?
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Offriamo sia pacchetti di pianificazione completi che servizi individuali. Puoi scegliere di affidarti a noi per l'intera organizzazione dell'evento o selezionare solo i servizi di cui hai bisogno.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							<Strong>
								3. Come posso ottenere un preventivo per il mio evento?
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Per ottenere un preventivo, contattaci tramite telefono o e-mail. Discuteremo i dettagli del tuo evento e le tue esigenze specifiche per fornirti un preventivo personalizzato.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							<Strong>
								4. Fornite servizi di catering?
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Sì, collaboriamo con i migliori ristoratori per offrire servizi di catering che includono opzioni di menu personalizzate per soddisfare tutti i gusti e le esigenze dietetiche.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							<Strong>
								5. Posso vedere alcuni esempi di eventi passati che avete organizzato?
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Certo! Possiamo fornirti un portfolio di eventi precedenti che abbiamo organizzato per darti un'idea del nostro lavoro e delle nostre capacità.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							<Strong>
								6. Come gestite la decorazione e lo styling degli eventi?
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Il nostro team di designer esperti lavora con te per creare un tema e uno stile che riflettano la tua visione. Offriamo servizi di design floreale, arredamento personalizzato, illuminazione professionale e molto altro.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							<Strong>
								7. Offrite supporto durante l'evento?
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Sì, il nostro team è presente sul posto durante l'evento per garantire che tutto si svolga senza intoppi. Coordiniamo i fornitori, gestiamo la sequenza temporale e risolviamo eventuali problemi che potrebbero sorgere.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" font="--lead">
				<Strong>
					Pronto a pianificare il tuo evento perfetto?
					<br />
				</Strong>
				A StarGaze Events, la nostra passione è rendere indimenticabile la tua occasione speciale. Contattaci oggi per iniziare a pianificare il tuo evento con il nostro team di esperti.
			</Override>
		</Components.Cta>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text6" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});